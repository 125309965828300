import Send from '../../cp.client.js'

export default {
    list (param) {
        return Send({
            url: '/bsa/bsaPerformanceData/list',
            method: 'get',
            params: {
                etdDtFr: param.etdDtFr,
                etdDtTo: param.etdDtTo,
                porCtr: param.porCtr,
                porPlc: param.porPlc,
                dlyCtr: param.dlyCtr,
                dlyPlc: param.dlyPlc,
                bkgShipper: param.bkgShipper
            }
        })
    }
}
