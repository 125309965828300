import Send from '../../cp.client.js'

export default {
    externalSFList (param) {
        return Send({
            url: '/bsa/bsaExternalVariables/bsaExternalList',
            method: 'get',
            params: {
                yearWeek: param.yearWeek,
                porCtr: param.porCtr,
                porPlc: param.porPlc,
                dlyCtr: param.dlyCtr,
                dlyPlc: param.dlyPlc,
                bkgShipper: param.bkgShipper,
                varCatCd: 'SF'
            }
        })
    },
    externalVCList (param) {
        return Send({
            url: '/bsa/bsaExternalVariables/bsaExternalList',
            method: 'get',
            params: {
                yearWeek: param.yearWeek,
                porCtr: param.porCtr,
                porPlc: param.porPlc,
                dlyCtr: param.dlyCtr,
                dlyPlc: param.dlyPlc,
                bkgShipper: param.bkgShipper,
                varCatCd: 'VC'
            }
        })
    },
    externalFIList (param) {
        return Send({
            url: '/bsa/bsaExternalVariables/bsaExternalList',
            method: 'get',
            params: {
                yearWeek: param.yearWeek,
                porCtr: param.porCtr,
                porPlc: param.porPlc,
                dlyCtr: param.dlyCtr,
                dlyPlc: param.dlyPlc,
                bkgShipper: param.bkgShipper,
                varCatCd: 'FI'
            }
        })
    },
    externalPerformanceList (param) {
        return Send({
            url: '/bsa/bsaExternalVariables/bsaExternalPerformanceList',
            method: 'get',
            params: {
                yearWeek: param.yearWeek,
                porPlc: param.porPlc,
                dlyPlc: param.dlyPlc,
                bkgShipper: param.bkgShipper
            }
        })
    }
}
