var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content full" },
    [
      _c("e-breadcrumbs"),
      _c(
        "win-layer-pop",
        { staticClass: "sample_popup_list" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.parentInfo },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("cp.CTRL060P010.001"))),
      ]),
      _c("div", { staticClass: "content_box mt10", attrs: { id: "frmReq" } }, [
        _c("table", { staticClass: "tbl_search" }, [
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL060P010.002")))]),
              _c("td", { staticClass: "cp-relative" }, [
                _c("input", {
                  staticClass: "cp-psel",
                  attrs: {
                    id: `yearWeekPsel`,
                    placeholder: _vm.setPselTxt("yearWeek"),
                    readonly: "",
                  },
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      return _vm.clickPsel("yearWeek")
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "cp-unitbox cp-popt cp-popt--close",
                    attrs: { id: `yearWeekPopt` },
                    on: {
                      mousedown: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "cp-li-header flex_box" }, [
                      _c("input", { attrs: { type: "checkbox", name: "no" } }),
                      _c(
                        "label",
                        {
                          class: {
                            "cp-pchkbox--disabled":
                              _vm.yearWeekList?.length === 0,
                            "cp-pchkbox--selected":
                              _vm.send.keyInput.yearWeek?.length !== 0 &&
                              _vm.yearWeekList.length ===
                                _vm.send.keyInput.yearWeek.length,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.controlPchk("yearWeek")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "offscreen" }, [
                            _vm._v("No"),
                          ]),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.poptSearchKeyword,
                            expression: "poptSearchKeyword",
                          },
                        ],
                        attrs: {
                          id: `yearWeekPoptSearch`,
                          type: "text",
                          placeholder: _vm.$t("cp.COMMON.001"),
                          disabled: _vm.yearWeekList?.length === 0,
                        },
                        domProps: { value: _vm.poptSearchKeyword },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.poptSearchKeyword = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm.yearWeekList?.length !== 0
                      ? _c(
                          "ul",
                          {
                            staticClass:
                              "cp-scroll cp-ul cp-ul--plc cp-ul--hide",
                          },
                          [
                            _c("li", { staticClass: "cp-li" }, [
                              _c("input", {
                                attrs: { type: "checkbox", name: "no" },
                              }),
                              _vm._m(1),
                              _c("span", [
                                _vm._v(_vm._s(_vm.setLongestText("yearWeek"))),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "ul",
                      { staticClass: "cp-scroll cp-ul cp-ul--plc" },
                      _vm._l(_vm.yearWeekList, function (item, idx) {
                        return _c(
                          "li",
                          {
                            key: idx,
                            staticClass: "cp-li",
                            class: {
                              "cp-li--selected":
                                _vm.send.keyInput.yearWeek.includes(
                                  item.yearWeek
                                ),
                              "cp-li--hide": _vm.searchPopt(
                                item.yearWeek,
                                item.yearWeek
                              ),
                            },
                            attrs: { value: idx },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.clickPopt("yearWeek", item.yearWeek)
                              },
                            },
                          },
                          [
                            _c("input", {
                              attrs: { type: "checkbox", name: "no" },
                            }),
                            _c(
                              "label",
                              {
                                class: {
                                  "cp-pchkbox--selected":
                                    _vm.send.keyInput.yearWeek.includes(
                                      item.yearWeek
                                    ),
                                },
                              },
                              [
                                _c("span", { staticClass: "offscreen" }, [
                                  _vm._v("No"),
                                ]),
                              ]
                            ),
                            _c("span", [_vm._v(_vm._s(item.yearWeek))]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL060P010.003")))]),
              _c("td", [
                _c("div", { staticClass: "tbl_form" }, [
                  _c("span", { staticClass: "dv" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.param.porCtr,
                            expression: "param.porCtr",
                          },
                        ],
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.param,
                                "porCtr",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.getHarborList(_vm.param.porCtr)
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                        ]),
                        _vm._l(_vm.countryList, function (item, idx) {
                          return _c(
                            "option",
                            { key: idx, domProps: { value: item.ctrCd } },
                            [_vm._v(_vm._s(item.ctrEnm))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("span", { staticClass: "dv" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.param.porPlc,
                            expression: "param.porPlc",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.param,
                              "porPlc",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                        ]),
                        _vm._l(_vm.harborList, function (item, idx) {
                          return _c(
                            "option",
                            { key: idx, domProps: { value: item.plcCd } },
                            [_vm._v(_vm._s(item.plcNm))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL060P010.004")))]),
              _c("td", [
                _c("div", { staticClass: "tbl_form" }, [
                  _c("span", { staticClass: "dv" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.param.dlyCtr,
                            expression: "param.dlyCtr",
                          },
                        ],
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.param,
                                "dlyCtr",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.getHarborList2(_vm.param.dlyCtr)
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                        ]),
                        _vm._l(_vm.countryList, function (item, idx) {
                          return _c(
                            "option",
                            { key: idx, domProps: { value: item.ctrCd } },
                            [_vm._v(_vm._s(item.ctrEnm))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("span", { staticClass: "dv" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.param.dlyPlc,
                            expression: "param.dlyPlc",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.param,
                              "dlyPlc",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                        ]),
                        _vm._l(_vm.harborList2, function (item, idx) {
                          return _c(
                            "option",
                            { key: idx, domProps: { value: item.plcCd } },
                            [_vm._v(_vm._s(item.plcNm))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
              _c("td", { staticClass: "text_right" }, [
                _c(
                  "a",
                  {
                    staticClass: "button blue sh",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.select.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("cp.COMMON.001")))]
                ),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL060P010.005")))]),
              _c("td", [
                _c("div", { staticClass: "tbl_form cp-relative" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button sm cp-td-button",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openPopup("CstSearchListPop", "bkgShipper")
                        },
                      },
                    },
                    [
                      _vm.param.bkgShipLength === 0 ||
                      _vm.param.bkgShipLength === undefined
                        ? [_vm._v(" " + _vm._s(_vm.$t("cp.COMMON.002")) + " ")]
                        : _vm.param.bkgShipLength === 1
                        ? [
                            _c("span", { staticClass: "cp-short-text" }, [
                              _vm._v(_vm._s(_vm.param.lastBkgShip)),
                            ]),
                          ]
                        : [
                            _c("span", { staticClass: "cp-short-text" }, [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.param.bkgShipLength) +
                                  ") " +
                                  _vm._s(_vm.param.lastBkgShip)
                              ),
                            ]),
                          ],
                    ],
                    2
                  ),
                ]),
              ]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL060P010.008")))]),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.param.salEnt,
                      expression: "param.salEnt",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.param.salEnt },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.param, "salEnt", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL060P010.009")))]),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.param.salUno,
                      expression: "param.salUno",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.param.salUno },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.param, "salUno", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "tab" }, [
        _c("ul", [
          _c(
            "li",
            {
              staticClass: "on",
              attrs: { id: "tab1" },
              on: {
                click: function ($event) {
                  return _vm.selectTab(1)
                },
              },
            },
            [
              _c("a", { attrs: { href: "javascript:void(0)" } }, [
                _vm._v(_vm._s(_vm.$t("cp.CTRL060P010.073"))),
              ]),
            ]
          ),
          _c(
            "li",
            {
              attrs: { id: "tab2" },
              on: {
                click: function ($event) {
                  return _vm.selectTab(2)
                },
              },
            },
            [
              _c("a", { attrs: { href: "javascript:void(0)" } }, [
                _vm._v(_vm._s(_vm.$t("cp.CTRL060P010.074"))),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tapState === 1,
              expression: "tapState === 1",
            },
          ],
          staticClass: "content_box",
        },
        [
          _c("div", {
            staticClass: "mt5",
            staticStyle: { width: "100%", height: "750px" },
            attrs: { id: "realgrid" },
          }),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tapState === 2,
              expression: "tapState === 2",
            },
          ],
          staticClass: "content_box",
        },
        [
          _c("div", {
            staticClass: "mt5",
            staticStyle: { width: "100%", height: "750px" },
            attrs: { id: "realgrid2" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }